<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <Dashboardx :data="dashboard" />
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import Dashboardx from '@/components/dashboard/Dashboard.vue'
import { SharedDashboardService } from '@/services'

export default {
  name: 'CCODashboard',

  middleware: ['auth', 'cco'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  components: {
    Dashboardx
  },

  data () {
    return {
      dashboard: {
        total_pending: 0,
        total_delivered: 0,
        total_rejected: 0,
        total_sales: 0,
        total_close: 0
      }
    }
  },

  mounted () {
    core.index()
    this.getDashboardDetails()
  },

  methods: {
    async getDashboardDetails () {
      const { data } = await SharedDashboardService.get()
      this.dashboard.total_pending = data?.total_pending
      this.dashboard.total_delivered = data?.total_delivered
      this.dashboard.total_rejected = data?.total_rejected
      this.dashboard.total_close = data?.total_close
      this.dashboard.total_sales = data?.total_sales.toLocaleString('en-US')
    }
  }
}
</script>
